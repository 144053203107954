<template>
  <div class="card mb-7">
    <!--begin::Card header-->
    <div class="card-header border-0">
      <!--begin::Card title-->
      <div
        class="
          card-title
          m-0
          w-100
          d-flex
          align-items-center
          justify-content-between
        "
      >
        <!--begin::Navs-->
        <label class="col-form-label flex-grow-1 flex-shrink-0 me-10"
          >Cari Wilayah</label
        >
        <div
          class="d-flex align-items-center justify-content-between w-100 ms-10"
        >
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKecamatan"
            v-model="form.kecamatan"
          >
            <option value="" selected>Pilih Kecamatan</option>
            <option
              v-for="(item, i) in kecamatan"
              :key="i"
              :value="item.territory.id"
            >
              {{ item.territory.name }}
            </option>
          </select>
          <select
            name=""
            id=""
            class="form-select me-2"
            @input="getKelurahan"
            v-model="form.kelurahan"
          >
            <option value="" selected>Pilih Kelurahan</option>
            <template v-if="kelurahan">
              <option
                v-for="(item, i) in kelurahan"
                :key="i"
                :value="item.territory.id"
              >
                {{ item.territory.name }}
              </option>
            </template>
          </select>
          <select
            @input="getTps"
            v-model="form.tps"
            name=""
            id=""
            class="form-select w-50"
          >
            <option value="" selected disabled hidden>Pilih TPS</option>
            <template v-if="tps">
              <option v-for="(item, i) in tps" :key="i" :value="item.tps.id">
                {{ item.tps.no_tps }}
              </option>
            </template>
          </select>
        </div>
        <!--end::Navs-->
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->
    <div class="card-body border-top p-2">
      <div class="row fw-bold">
        <div class="col-md-12 mb-5">
          Perolehan Suara Partai & Caleg PKS  {{ form.label }}
        </div>
        <div class="col-md-6">
          <div class="row mb-5">
            <div class="col-md-4 d-flex align-items-center">No. TPS</div>
            <div class="col-md-8">
              <input
                type="number"
                readonly
                class="form-control form-control-solid w-25"
                v-model="form.noTps"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 d-flex align-items-center">
              Nama Kordinator
            </div>
            <div class="col-md-8">
              <input
                type="text"
                readonly
                class="form-control form-control-solid"
                v-model="form.koor"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4 d-flex align-items-center">Nama Saksi</div>
            <div class="col-md-8">
              <input
                type="text"
                readonly
                class="form-control form-control-solid w-10"
                v-model="form.saksi"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-body border-top p-0">
      <div class="row">
        <div class="col-lg-4">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table table-row-bordered mb-0">
              <thead>
                <tr class="fw-bold fs-6 text-gray-800 text-center">
                  <th>Partai</th>
                  <th>Jumlah Suara Sah</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in party" :key="i">
                  <td class="border-end">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <img
                        :src="path + item.party.logo"
                        alt="logo partai"
                        class="img-fluid"
                        style="max-height: 50px"
                      />
                      <span class="fw-bolder"> {{ item.party.name }} </span>
                    </div>
                  </td>
                  <td class="text-end">
                    <span class="me-2"
                      >{{ Intl.NumberFormat().format(item.party.count) }}
                    </span>
                  </td>
                </tr>
                <tr class="bg-light">
                  <td class="border-end">
                    <span class="ms-2"> Jumlah Suara Sah </span>
                  </td>
                  <td class="text-end">
                    <span class="me-2">
                      {{ Intl.NumberFormat().format(total) }}
                    </span>
                  </td>
                </tr>
                <tr class="bg-light">
                  <td class="border-end">
                    <span class="ms-2"> Jumlah Suara Tidak Sah </span>
                  </td>
                  <td class="text-end">
                    <span class="me-2"> ?? </span>
                  </td>
                </tr>
                <tr class="bg-light">
                  <td class="border-end">
                    <span class="ms-2"> Jumlah DPT </span>
                  </td>
                  <td class="text-end">
                    <span class="me-2"> ?? </span>
                  </td>
                </tr>
                <tr class="bg-light">
                  <td class="border-end">
                    <span class="ms-2"> Selisih Pemilih dan DPT </span>
                  </td>
                  <td class="text-end">
                    <span class="me-2"> ?? </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table table-row-bordered mb-0">
              <thead>
                <tr class="fw-bold fs-6 text-gray-800 text-center">
                  <th>No Urut & Nama Caleg</th>
                  <th>Jumlah Suara Sah</th>
                  <!-- <th class="w-25">Form C1</th> -->
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in candidate" :key="i">
                  <tr class="bg-light">
                    <td class="border-end">
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <span class="ms-2 me-4"> {{ i + 1 }}. </span>
                        <img
                          :src="path + item.party.logo"
                          alt="logo partai"
                          class="img-fluid"
                          style="max-height: 50px"
                        />
                        <span class="fw-bolder"> {{ item.party.name }} </span>
                      </div>
                    </td>
                    <td class="border-end text-end">
                      <span class="me-1">
                        {{ Intl.NumberFormat().format(item.party.count) }}
                      </span>
                    </td>
                    <!-- <td :rowspan="item.candidates.length + 1" class="bg-white">
                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-center
                          flex-column
                        "
                        v-if="form.noTps && item.tps"
                      >
                       <img :src="item.tps.c_one" class="img-fluid mt-2" alt="" />
                          <a class="btn btn-sm btn-default" target="_blank" :href="item.tps.c_one">
                            <i class="bi bi-cloud-arrow-down-fill"></i>
                            Download
                          </a>
                      </div>
                    </td> -->
                  </tr>
                  <tr v-for="(detail, j) in item.candidates" :key="j">
                    <td class="border-end">
                      <span class="ms-2"> {{ j + 1 }}.</span> {{ detail.name }}
                    </td>
                    <td class="text-end border-end">
                      <span class="me-2">
                        {{ Intl.NumberFormat().format(detail.count) }}
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
export default {
  setup() {
    const store = useStore();
    setCurrentPageTitle("Hasil Pemilu");
    store.commit("SET_ACTIVE_MENU", "result");
    store.dispatch("AllTerritory", { type: 4 });
    store.dispatch("getPartyResult");
    store.dispatch("getCandidateResult");

    const form = ref({
      kecamatan: "",
      kelurahan: "",
      tps: "",
      label: "Kota Tanggerang Selatan",
      noTps: 0,
      koor: "",
      saksi: "",
    });

    const path = window.location.origin + "/other/png/partai/";
    const party = computed(() => store.state.ResultModule.party);
    const candidate = computed(() => store.state.ResultModule.candidate);

    const kecamatan = computed(() => store.state.TerritoryModule.kecamatan);
    const kelurahan = computed(() => store.state.TerritoryModule.kelurahan);
    const tps = computed(() => store.state.ResultModule.tps);
    const detailTps = computed(() => store.state.ResultModule.detailTps);
    const total = ref(0);

    watch(party, () => {
      total.value = 0;
      if (party.value) {
        party.value.map((item) => {
          total.value += item.party.count;
        });
      }
    });

    watch(detailTps, () => {
      if (detailTps.value) {
        const item = detailTps.value;
        form.value.label =
          "Kecamatan " +
          item.parent_territory.name +
          " - " +
          item.territory.name;
        form.value.noTps = item.tps.no_tps.replace("TPS ", "");
        form.value.koor = item.coordinator.name;
        form.value.saksi = item.witness.name;
      } else {
        form.value.label = "Kota Tanggerang Selatan";
        form.value.noTps = 0;
        form.value.koor = "";
        form.value.saksi = "";
      }
    });

    const getKecamatan = (el) => {
      form.value.kelurahan = "";
      form.value.tpsId = "";
      store.commit("SET_TPS_RESULT", { tpses: [] });
      if (el.target.value) {
        store.dispatch("AllTerritory", { type: 5, parent_id: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateTanpaWilayah();
        store.commit("SET_ALL_TERRITORY", { type: 5, territories: [] });
      }
    };

    const getKelurahan = (el) => {
      if (el.target.value) {
        store.dispatch("getTPSResult", { territory: el.target.value });
        updateDataWilayah(el.target.value);
      } else {
        updateDataWilayah(form.value.kecamatan);
        form.value.tpsId = "";
        store.commit("SET_TPS_RESULT", { tpses: [] });
      }
    };

    const getTps = (el) => {
      updateDataTPS(el.target.value);
    };

    const updateDataWilayah = (wilayah) => {
      store.dispatch("getPartyResult", {
        territory: wilayah,
      });
      store.dispatch("getCandidateResult", {
        territory: wilayah,
      });
    };

    const updateTanpaWilayah = () => {
      store.dispatch("getPartyResult");
      store.dispatch("getCandidateResult");
    };

    const updateDataTPS = (tps) => {
      const wilayah = form.value.kelurahan
        ? form.value.kelurahan
        : form.value.kecamatan;
      store.dispatch("getPartyResult", {
        territory: wilayah,
        tps: tps,
      });
      store.dispatch("getCandidateResult", {
        territory: wilayah,
        tps: tps,
      });
      store.dispatch("getDetailTPSResult", {
        tps: tps,
      });
    };
    return {
      party,
      candidate,
      form,
      path,
      total,
      tps,
      detailTps,
      kecamatan,
      kelurahan,
      getKecamatan,
      getKelurahan,
      getTps,
    };
  },
};
</script>
